import cn from "@/utils/cn";

export function Google({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-[1em]", className)}
      {...props}
    >
      <path
        d="M16 8.68184C16 8.11457 15.9481 7.56911 15.8516 7.04548H8.16326V10.14H12.5566C12.3673 11.14 11.7922 11.9873 10.9276 12.5546V14.5618H13.5659C15.1095 13.1691 16 11.1182 16 8.68184Z"
        fill="#4285F4"
      />
      <path
        d="M8.16327 16.5C10.3674 16.5 12.2152 15.7836 13.5659 14.5618L10.9276 12.5546C10.1967 13.0346 9.26161 13.3181 8.16327 13.3181C6.03711 13.3181 4.23748 11.9109 3.59555 10.02H0.868279V12.0927C2.21151 14.7072 4.97218 16.5 8.16327 16.5Z"
        fill="#34A853"
      />
      <path
        d="M3.59555 10.02C3.43229 9.53996 3.33952 9.02729 3.33952 8.50002C3.33952 7.97274 3.43228 7.46002 3.59555 6.98002V4.90729H0.868275C0.315399 5.98729 0 7.20911 0 8.50002C0 9.79092 0.315403 11.0127 0.868279 12.0927L3.59555 10.02Z"
        fill="#FBBC05"
      />
      <path
        d="M8.16327 3.6818C9.36179 3.6818 10.4379 4.08543 11.2839 4.87816L13.6252 2.58362C12.2115 1.29271 10.3636 0.499985 8.16327 0.499985C4.97218 0.499985 2.2115 2.29275 0.868275 4.90729L3.59555 6.98002C4.23748 5.08911 6.03711 3.6818 8.16327 3.6818Z"
        fill="#EA4335"
      />
    </svg>
  );
}
